import React from "react";





const RHome = () => {


    return(
        <>
        <div>
        <h1>Welcome to Ryder, coming soon!</h1>
        
        </div>
        
        
        
        
        </>

    )
}
export default RHome;