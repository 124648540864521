export const BrandItems = [
    {
        title: 'LUX',
        path: '/lux',
        cName: 'dropdown-link'
    },
    // {
    //     title: 'HASH',
    //     path: '/hash',
    //     cName: 'dropdown-link'
    // },
    // {
    //     title: 'Ryder',
    //     path: '/ryder',
    //     cName: 'dropdown-link'
    // }
];