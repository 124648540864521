import React from 'react';

import "./ContactUs.css"

import EmailForm from './EmailForm';

const ContactUs = () => {


    return (
        <>
            <div className='form-container'>
                <div className="white-space">

                </div>
                
                <EmailForm/>
            </div>
        </>
    );
}

export default ContactUs;