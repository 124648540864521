import React from "react";





const HaHome = () => {


    return(
        <>
        <div>
        <h1>Welcome to Hash Home</h1>
        
        </div>
        
        
        
        
        </>

    )
}
export default HaHome;