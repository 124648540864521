import creamicon from "../images/LUX_logos_cream.png";
import carpack from "../images/Caramel_Macchiato_w_packaging_lux.png"
import carproduct from "../images/Caramel_Macchiato_up_close.png"
import tripack from "../images/LUX_tri_packaging.png"
import darkbrownimg from "../images/big_cool_brown-pic.png"
import sscarpack from  "../images/Sea_Salt_Caramel_pack.png"




export const pics = 
[
{title: "Sea Salt pack", subtitle:'wont see', img:sscarpack},
{title: "Caramel Macc product", subtitle:"choco icon", img: carproduct},
{title: "Caramel Macc pack", subtitle:"second of the lux logos", img: carpack},
{title: "icon cream", subtitle:"cream icon", img: creamicon},
{title: "Cool DBrown Pic", subtitle: "wont see", img:darkbrownimg },
{title: "tri-pack", subtitle:"dark choco icon", img: tripack}



];